// @flow

import React, { Suspense } from 'react'
import { generatePath, matchPath, useLocation } from 'react-router'
import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom'
import { useEditor, WebsiteEditorContext } from '@shift-marketing/shift-one-website-editor'

import { Store } from 'Store'
import { fetchCampaign } from 'Actions'

import CampaignNotFound from 'components/CampaignNotFound'

import Landing from 'pages/Landing'
import WebsitesPreview from 'pages/WebsitesPreview'

import globalPropertiesSchema from './globalProperties.schema.json'

const IS_CAMPAIGN_URL = /^\/.+$/.test(window.location.pathname)

export const AppRoute = ({ component: Component, layout: Layout = MainLayout, ...rest }) => (
    <Route {...rest} render={props => (
        <Layout>
            <Component {...props} />
        </Layout>
    )}/>
)

const MainLayout = (props) => (<>{props.children}</>)

export function AppLoader () {
    const { state, dispatch } = React.useContext(Store)

    React.useEffect(() => {
        fetchCampaign(dispatch)
    }, [dispatch])

    React.useEffect(() => {
        if (IS_CAMPAIGN_URL && state.campaign === null) return

        document.title = `${state.campaign.client.name}`

        setTimeout(() => {
            document.body.classList.remove('loading')
        }, 500)
    }, [state.campaign])

    if (state.errors.campaignNotFound) {
        return <CampaignNotFound />
    }

    if (IS_CAMPAIGN_URL && (state.campaign === null)) {
        return <h1>Loading</h1>
    }

    return <App/>
}

const DEFAULT_PROPERTIES = {}

function App () {
    const { state } = React.useContext(Store)
    useEditor(state.campaign.website, globalPropertiesSchema, DEFAULT_PROPERTIES)

    return (
        <Suspense fallback={<div>loading...</div>}>
            <BrowserRouter>
                <AppSwitch>
                    <AppRoute exact editorPageTitle='Landing Page' path="/:url" component={Landing}/>
                    <AppRoute exact editorPageTitle='Landing Page' path="/:url/websites" component={WebsitesPreview}/>
                </AppSwitch>
            </BrowserRouter>
        </Suspense>
    )
}

function AppSwitch (props) {
    const { addPage, editMode } = React.useContext(WebsiteEditorContext)
    const history = useHistory()
    const location = useLocation()

    // Find the page that matches the current location
    const matchedPage = React.Children.toArray(props.children).find(p => matchPath(location.pathname, {
        path: p.props.path,
        exact: p.props.exact,
        strict: p.props.strict
    }) !== null)

    // extract the :url value
    const { params: { url } } = matchPath(location.pathname, {
        path: matchedPage.props.path // we assume the first page is the landing page
    })

    React.useEffect(() => {
        if (!editMode) return

        React.Children.forEach(page => {
            if (!page.props.editorPageTitle) return

            addPage(
                page.props.editorPageTitle,
                function () {
                    history.push(generatePath(page.props.path, { url }))
                }
            )
        })
    }, [editMode, url, addPage, history])

    return <Switch>{props.children}</Switch>
}
