// @flow
import React from 'react'
import cn from 'classnames'
import Select from 'react-select'

import styles from './styles.module.scss'

import CAMPAIGNS_PREVIEWS from './campaign-previews.json'

export default function WebsitesPreview () {
    const [eventType, setEventType] = React.useState<?string>(null)
    const [style, setStyle] = React.useState<?string>(null)
    const [previewType, setPreviewType] = React.useState<?string>(null)

    const wrapperRef = React.useRef()

    const onCampaignTypeChange = React.useCallback(type => {
        setEventType(type.value)
        setStyle(null)
        setPreviewType('website')
    }, [])

    const onStyleChange = React.useCallback(style => {
        setStyle(style.value)
    }, [])

    const previewUrl = React.useMemo(() => {
        return eventType && style && previewType
            ? CAMPAIGNS_PREVIEWS[eventType]?.styles[style]?.links[previewType] || null
            : null
    }, [eventType, previewType, style])

    const eventTypeOptions = React.useMemo(() => (
        Object.entries(CAMPAIGNS_PREVIEWS).map(([e, data]) => ({ value: e, label: data.label }))
    ), [])

    const header = (
        <div className={styles.header}>
            <div className={styles.headerItem}>
                <Select
                    placeholder="Select campaign type"
                    size={'medium'}
                    options={eventTypeOptions}
                    // value={eventType}
                    onChange={onCampaignTypeChange}
                    allowSearch
                />
            </div>

            {eventType && Object.keys(CAMPAIGNS_PREVIEWS[eventType].styles).length && (
                <div className={styles.headerItem}>
                    <Select
                        placeholder="Select style"
                        options={Object.keys(CAMPAIGNS_PREVIEWS[eventType].styles).map(s => ({
                            value: s,
                            label: s.split('-').map(capitalizeWord).join(' ')
                        }))}
                        defaultValue={style}
                        onChange={onStyleChange}
                    />
                </div>
            )}

            {/*{style && (*/}
            {/*    <div className={styles.headerItem}>*/}
            {/*        <Select*/}
            {/*            placeholder="Select type"*/}
            {/*            size={'medium'}*/}
            {/*            options={Object.keys(CAMPAIGNS_PREVIEWS[eventType][style].links)}*/}
            {/*            onChange={onPreviewTypeChange}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*)}*/}
        </div>
    )

    return (<>
        <div className={styles.wrapper} ref={wrapperRef}>
            {header}
            <div className={styles.content}>
                <div className={styles.deviceContainer}>
                    <iframe className={cn(styles.iframe, { [styles.isLoaded]: previewUrl !== null })} src={previewUrl} frameBorder="0" title="preview"/>
                </div>
            </div>
        </div>
    </>)
}

function capitalizeWord (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
