import React from 'react'

import styles from './styles.module.scss'

export default function Typography (props) {
    return (
        <div className={styles.typography}>
            {props.children}
        </div>
    )
}
