// @flow
import React from 'react'
import queryString from 'query-string'
import { TextBlock } from '@shift-marketing/shift-one-website-editor'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import withTheme from 'hoc/withTheme'

import { fetchLead, patchLead } from 'Actions'
import { Store } from 'Store'

import LandingPageFeatureImage from 'components/LandingPageFeatureImage'

import styles from './styles.module.scss'

import { withTranslation } from 'react-i18next'
import transDomain from './translations/index.translations'

import themes, { type Theme } from './themes/__supportedThemes'
import Typography from 'components/Typography'

import iconView from './images/icon-view.svg'
import logo from './images/logo.svg'

type Props = {
    history: any,
    location: any,
    match: any,
    theme: Theme,
    t: any,
};

function Landing (props: Props) {
    const { dispatch, state } = React.useContext(Store)
    const { t } = props
    const [formState, setFormState] = React.useState(null)

    const history = useHistory()
    let { url } = useParams();

    const handleSubmit = React.useCallback(async e => {
        e.preventDefault()
        setFormState('Redirecting...')

        try {
            let code = e.target.elements['code'].value
            await fetchLead(dispatch, code)
            await patchLead(dispatch, code, {}, true)

            history.push(url + '/websites')

            setFormState('Success')
        } catch (err) {
            console.error(err)
            setFormState(null)
        }
    }, [dispatch, history, url])

    return (
        <div className={styles.container}>
            <aside className={styles.leftSide}>
                <div className={styles.logo}>
                    <img src={logo} alt="Logo"/>
                </div>

                <div className={styles.img}>
                    <LandingPageFeatureImage/>
                </div>
            </aside>

            <div className={styles.rightSide}>
                <div className={styles.rightSideInner}>
                    <div className={styles.logoMobile}>
                        <img src={logo} alt="Logo"/>
                    </div>

                    <div className={styles.content}>
                        <Typography>
                            <TextBlock
                                blockId={'landing.topMessage'}
                                defaultContent={`<h2>Download your product sheet today<br /><strong>(it's free!).</strong></h2>`}
                                locale={state.locale}
                                values={{}}
                            />
                        </Typography>

                        <form className={styles.form} onSubmit={handleSubmit}>
                            <input
                                name="code"
                                type="text"
                                placeholder="Enter your code"
                                defaultValue={queryString.parse(window.location.hash)['code']}
                            />
                            <button disabled={formState !== null}>
                                {formState || 'View websites'}
                                <img src={iconView} alt="View websites" className={styles.icon}/>
                            </button>
                        </form>

                        <Typography>
                            <TextBlock
                                blockId={'landing.bottomMessage'}
                                defaultContent={`
                                <h3>What's inside:</h3>
                                <p>Amazing new features</p>
                                <p>Top tier support</p>
                                <p>Many more...</p>
                            `}
                                locale={state.locale}
                                values={{}}
                            />
                        </Typography>
                    </div>

                    <footer className={styles.footer}>
                        <span>
                            {t('footer.line1')}
                        </span>
                        <span>
                            {t('footer.line2')}
                        </span>
                    </footer>
                </div>
            </div>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(themes)(Landing))
