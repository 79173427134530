// @flow
import React from 'react'
import { useBlock } from '@shift-marketing/shift-one-website-editor'

import propertiesSchema from './properties.schema.json'

import styles from './styles.module.scss'

import image from './placeholder.png'

const DEFAULT_PROPERTIES = { image }

export default function LandingPageFeatureImage () {
    const { properties, ref } = useBlock('landing.featuredImage', propertiesSchema, DEFAULT_PROPERTIES)

    return (
        <div className={styles.image} ref={ref}>
            <img src={properties.image} alt="Featured"/>
        </div>
    )
}
